<template>
    <div class="container"
         :class="[`p_${editPoll ? editPoll.Code : ''}`]">
        <vue-title :title="title" />

        <div v-if="!editPoll && !error && !pollClosedMessage" class="submit-overlay">
            <div class="submit-contents text-white">
                <h4>
                    Loading, please wait...
                </h4>
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>

        
        <div v-if="editPoll && !success && !showSuccessOnly && editPoll.LandingPageMessage && (showLandingPage || previewMode)">
            <markdown-output :content="editPoll.LandingPageMessage" />
        </div>

        <div v-if="pollClosedMessage">
            <markdown-output class="text-center text-content" :content="pollClosedMessage" />
        </div>

        <div v-if="error" class="alert alert-danger mt-3 mb-3">
            <h2>{{error}}</h2>
            <p>Double-check the link and try again.</p>
        </div>
        <div v-if="success || showSuccessDebug || showSuccessOnly">
            <markdown-output class="text-center text-content" :content="conclusionText" />
        </div>


        <div v-if="editPoll && !success && !showSuccessOnly && !showLandingPage">
            <div v-if="validateErrors && validateErrors.length > 0"
                 class="alert alert-danger mt-2"
                 role="alert">
                <h5>We couldn't save your answers. Please try again.</h5>
                <ul>
                    <li v-for="error in validateErrors" v-bind:key="error">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <div v-if="editPoll.HeaderText"
                 class="text-center text-content">
                <markdown-output :content="editPoll.HeaderText" />
            </div>

            <form autocomplete="on">
                <div class="form-group mt-2"
                     v-for="(question) in editPoll.Questions"
                     :key="question.Code">
                    <poll-answer
                        :bus="bus"
                        :disabled="denyResponseEditCheck(question.Code)"
                        v-if="question.QuestionType != 'INTERNAL'"
                        v-on:responseChanged="responseChanged"
                        v-on:groupInsert="groupInsert"
                        v-on:groupDelete="groupDelete"
                        v-bind:question="question"
                        v-bind:poll="editPoll"
                        v-bind:submitting="submitting"
                        :debugMode="debugModeEnabled"
                    />
                </div>
            </form>

            <div v-if="validateErrors && validateErrors.length > 0"
                 class="alert alert-danger"
                 role="alert">
                <h5>We couldn't save your answers. Please try again.</h5>
                <ul>
                    <li v-for="error in validateErrors" v-bind:key="error">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <div class="text-center">
                <button @submit.prevent
                        type="submit"
                        :disabled="submitting"
                        class="btn btn-success m-3 btn-lg btn-survey-submit"
                        v-on:click="onSubmitPollResponse">
                        <span> {{ editPoll.SubmitButtonText || "Submit" }} </span>
                </button>
            </div>


            <footer class="footer fs-6 text-center mt-auto border-top p-3"
                    id="footer"
                    v-if="editPoll.FooterContent">
                <markdown-output class="text-center text-content" :content="editPoll.FooterContent" />
            </footer>
        </div>

        
        <div v-if="submitting" class="submit-overlay">
            <div class="submit-contents text-white">
                <h4>
                    Submitting, please wait...
                </h4>
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Saving...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.poll-title-image {
    max-height: 2in;
}

.submit-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.8);
}

.submit-overlay > .submit-contents > div {
    margin-top: 1em;
}

.submit-overlay > .submit-contents {
    display: flex;
    flex-direction: column;
    padding: 3em;
    border-radius: 8px;
    align-items: center;
}

</style>
<script>
import Vue from "vue";
import PollQuestion from './PollQuestion'
import validator from 'validator';

export default {
    props: ["showCode", "pollCode"],

    name: "PublicPollResponse",
    data() {
        return {
            embed: false,

            submitting: false,
            bus: new Vue(),
            poll: null,
            show: null,
            error: null,
            validateErrors: [],

            success: null,
            incrementGroup: 0,
            responseCheckCodeArray: [],
            ex: null,
            editPoll: null,
            pollQuestionGroupTemplates: [],
            fulfilledAttendees: [],

            headerDebugClicks: 0,
            pollClosedMessage: null,
            showLandingPage: false,
        };
    },

    computed: {

        currentScore() {
            if(!this.editPoll) {
                return 0;
            }

            try {
                let questions = this.editPoll.Questions.filter(x => x.Answers && x.Answers.length > 0 && x.Answers.find(y => y.IsCorrect));

                if(!questions || questions.length == 0) {
                    return 0;
                }

                let correctQuestions = questions.filter(x => x.Answers.find(y => y.IsCorrect && y.Response == x.Response));

                return correctQuestions.length;

            } catch {
                return 0;
            }
        },

        maxScore() {
            if(!this.editPoll) {
                return 0;
            }

            try {

                let questions = this.editPoll.Questions.filter(x => x.Answers && x.Answers.length > 0 && x.Answers.find(y => y.IsCorrect));

                if(!questions || questions.length == 0) {
                    return 0;
                }

                return questions.length;
            } catch {

            }
        },

        title: function () {
            if (this.editPoll) {
                return this.editPoll.Name + " | Tractus Events";
            } else if (this.pollClosedMessage) {
                return "Form Closed | Tractus Events";                
            }

            return "Loading... | Tractus Events";
        },

        conclusionText: function () {
            var defaultText = "Thank you for your response!";
            if (!this.editPoll) {
                return defaultText;
            }

            if(this.show && this.show.IsPublicShow
                && this.editPoll.ConclusionMessage) {

                let eventUrl = `${process.env.VUE_APP_LANDING_URL}/#/${this.show.Code}`;

                return this.editPoll.ConclusionMessage
                    .replace('{{LoginLink}}', eventUrl)
                    .replace('{{LoginButton}}', `<a href='${eventUrl}' class='btn btn-lg btn-primary' target='_blank'>Click Here to Access the Show</a>`)
                    .replace('{{CurrentScore}}', this.currentScore)
                    .replace('{{MaxScore}}', this.maxScore)
                    ;


            } else if(this.fulfilledAttendees
                && this.fulfilledAttendees.length > 0
                && this.editPoll.ConclusionMessage) {
                
                if(this.fulfilledAttendees.length == 1) {
                    let attendee = this.fulfilledAttendees[0];
                    let loginUrl = this.getAttendeeLoginUrl(attendee);
                    let eventUrl = `${process.env.VUE_APP_EVENT_URL}${loginUrl}`;

                    return this.editPoll.ConclusionMessage
                        .replace('{{LoginLink}}', eventUrl)
                        .replace('{{LoginButton}}', `<a href='${eventUrl}' class='btn btn-lg btn-primary' target='_blank'>Click Here to Access the Show</a>`)
                    ;

                } else {

                }

            }

            return (this.editPoll.ConclusionMessage || defaultText);
        },

        conclusionTitle: function () {
            var defaultText = "Thank You!";
            if (!this.editPoll) {
                return defaultText;
            }

            return this.editPoll.ConclusionTitle || defaultText;
        },

        showSuccessDebug() {
            return (this.$route && this.$route.query && this.$route.query.debugsuccess)
                || (this.$route && this.$route.query && this.$route.query.preview);
        },

        showSuccessOnly() {
            return this.$route && this.$route.query && this.$route.query.sfc;
        },

        previewMode() {
            return this.$route && this.$route.query && this.$route.query.preview;
        },

        debugModeEnabled() {
            return this.headerDebugClicks >= 9;
        },

    },

    beforeDestroy() {
        
    },

    created: function () {

        window.addEventListener('click', (e) => {
            if(!e && !e.target) {
                return;
            }
            console.log(e, e.target.dataset, this);
            if(e.target.dataset && e.target.dataset.role === 'enter') {
                this.showLandingPage = false;
            }
        });

        if (this.$route.query.embed) {
            this.embed = true;

            document.getElementById('footer').style.cssText = "display: none;";
        }

        if(this.$route.query.preview) {
            window.addEventListener('message', (e) => {
                const data = e.data;
                if(!e.data.show) {
                    return;
                }
                                
                this.show = e.data.show;
                this.poll = e.data.poll;
                this.editPoll = e.data.poll;
                this.rebuildTheme();
                console.log("Got data: ", data);
            });
        } else {
            this.onGetPoll();
        }

        window.downloadICal = this.downloadIcalLoginReminder
    },

    watch: {
        pollCode(to, from) {
            if (to != from) {
                this.onGetPoll();
            }
        },
    },

    methods: {

        getOutlook365Url() {
            return this.getMicrosoftOutlookUrl(true);
        },

        getHotmailUrl() {
            return this.getMicrosoftOutlookUrl(false);
        },

        getMicrosoftOutlookUrl(outlook365) {
            const root = outlook365 
                ? 'https://outlook.office.com/calendar/0/deeplink/compose'
                : 'https://outlook.live.com/calendar/0/deeplink/compose';

            const path = encodeURIComponent("/calendar/action/compose");

            const startDt = encodeURIComponent(this.show.StartDateTime);

            const endDt = encodeURIComponent(this.show.EndDateTime);

            const subject = encodeURIComponent(this.show.Name);

            const location = encodeURIComponent("See Enclosed");

            const body = encodeURIComponent(`<a href="${this.getJoinShowUrl()}">Click Here to Join</a>`);

            const url = `${root}?path=${path}&rru=addevent&startdt=${startDt}&enddt=${endDt}&subject=${subject}&location=${location}&body=${body}`;

            return url;
        },

        getGoogleCalendarUrl() {
            const root = 'https://calendar.google.com/calendar/render';

            const startTimeGoogle = this.getDateAsICalStamp(this.show.StartDateTime);

            const endTimeGoogle = this.getDateAsICalStamp(this.show.EndDateTime);

            const sessionDt = encodeURIComponent(`${startTimeGoogle}/${endTimeGoogle}`);

            const subject = encodeURIComponent(this.show.Name);

            const location = encodeURIComponent("See Enclosed");

            const body = encodeURIComponent(`<a href="${this.getJoinShowUrl()}">Click Here to Sign In</a>`);

            const url = `${root}?action=TEMPLATE&dates=${sessionDt}&text=${subject}&location=${location}&details=${body}`;
            return url;            
        },


        getDateComponents(date) {
            let d = date ? new Date(date) : new Date();
            let month = (d.getUTCMonth() + 1).toString();
            let day = d.getUTCDate().toString();
            let year = d.getUTCFullYear().toString();

            let hour = d.getUTCHours().toString();
            let minute = d.getUTCMinutes().toString();
            
            if (month.length < 2) {
                month = '0' + month;
            }
            
            if (day.length < 2) {
                day = '0' + day;
            }

            if(hour.length < 2) {
                hour = '0' + hour;
            }

            if(minute.length < 2) {
                minute = '0' + minute;
            }


            return [year, month, day, hour, minute];
        },

        formatAsIcalStamp(dtStamp) {
            return `${dtStamp[0]}${dtStamp[1]}${dtStamp[2]}T${dtStamp[3]}${dtStamp[4]}00Z`;
        },

        getDateAsICalStamp(date) {
            return date 
                ? this.formatAsIcalStamp(this.getDateComponents(new Date(date)))
                : this.formatAsIcalStamp(this.getDateComponents(new Date()));
        },

        getAttendeeLoginUrl() {
            if(this.show.IsPublicShow) {
                return `/#/${this.show.Code}`
            }

            if(this.fulfilledAttendees.length == 1) {
                let attendee = this.fulfilledAttendees[0];
                return `/login?l=${btoa(`${attendee.EntityCode}:${attendee.Pin}:${this.show.Code}`)}&dl=true`;
            }

            return null;
        },

        getJoinShowUrl() {
            let rootUrl = this.show.IsPublicShow
                ? process.env.VUE_APP_LANDING_URL
                : process.env.VUE_APP_EVENT_URL;

            let loginPath = this.getAttendeeLoginUrl();

            return `${rootUrl}${loginPath}`;
        },

        async downloadIcalLoginReminder() {
            // 20211211T133000Z
            // YYYYMMDDTHHmmSSZ

            let dtStamp = this.getDateComponents();
            dtStamp = this.formatAsIcalStamp(dtStamp);

            let dtStart = this.getDateAsICalStamp(this.show.StartDateTime);
            let dtEnd = this.getDateAsICalStamp(this.show.EndDateTime);

            let eventSummary = this.show.Name;
            let eventDescription = `${this.show.Name}\\n\\nClick the link below to sign in\\n${this.getJoinShowUrl()}`;

            let eventUid = new Date().getTime();
            
            let iCalResponse = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//tractusevents.com//iCalEngine
BEGIN:VEVENT
DTEND:${dtEnd}
DTSTAMP:${dtStamp}
DTSTART:${dtStart}
LOCATION:See Enclosed
SEQUENCE:0
SUMMARY:${eventSummary}
DESCRIPTION:${eventDescription}
UID:${eventUid}
END:VEVENT
END:VCALENDAR`;

            let fileName = `tractusreminder.ics`;
            let blob = new Blob([iCalResponse], {
                type: 'text/calendar'
            });

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
            console.log(iCalResponse)
        },

        rebuildTheme() {

            let style = document.getElementById('theme-stylesheet');
            if(style) {
                style.innerHTML = '';
            } else {
                style = document.createElement('style');
                style.setAttribute('id', 'theme-stylesheet');
                document.head.appendChild(style);
            }

            if(this.show.Theme
                && this.show.Theme.StyleSheet) {
                
                let candidateSheet = this.show.Theme.StyleSheet;

                style.innerHTML = candidateSheet;   
            }

            if(this.$route.query && this.$route.query.bg) {
                style.innerHTML = style.innerHTML + `\r\nbody{ background: ${this.$route.query.bg} !important; }`
            }

            if(this.$route.query && this.$route.query.fg) {
                style.innerHTML = style.innerHTML + `\r\n.text-content, .text-content > *, .text-content > * > * { color: ${this.$route.query.fg} !important; }`
            }

        },
        
        rgba2hex(orig) {
            var a, isPercent,
                rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
                alpha = (rgb && rgb[4] || "").trim(),
                hex = rgb ?
                (rgb[1] | 1 << 8).toString(16).slice(1) +
                (rgb[2] | 1 << 8).toString(16).slice(1) +
                (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

            if (alpha !== "") {
                a = alpha;
            } else {
                a = 1;
            }
            // multiply before convert to HEX
            // a = ((a * 255) | 1 << 8).toString(16).slice(1)
            // hex = hex + a;

            return hex;
        },


        incrementHeaderDebugClick() {
            this.headerDebugClicks = (this.headerDebugClicks + 1) % 11;
        },

        groupDelete: function (groupTag) {
            let subGroup = groupTag.SubGroup;
            let group = groupTag.GroupTag;
            var filtered = this.editPoll.Questions.filter(
                (x) => !x.SubGroup || x.SubGroup != subGroup
            );
            this.editPoll.Questions = filtered;
            this.bus.$emit("updateVisibility");
            this.bus.$emit("deleteGroup", group);
        },

        denyResponseEditCheck(questionCode) {
            if (
                this.responseCheckCodeArray &&
                this.responseCheckCodeArray.length == 0
            ) {
                return false;
            }

            return this.responseCheckCodeArray.includes(questionCode);
        },

        responseChanged: function () {
            this.bus.$emit("updateVisibility");
        },

        groupInsert: function (groupTag) {
            let groupOfQuestionsToAdd = [];

            let questionsInGroupWithSubGroup = this.editPoll.Questions.filter(x => x.SubGroup && x.GroupTag && x.GroupTag == groupTag && x.QuestionType == 'GroupDeleteButton');

            let questionsWithGroup = questionsInGroupWithSubGroup.length;

            let newGroupTag = `${groupTag}${questionsWithGroup}`;

            while(this.editPoll.Questions.filter(x => x.SubGroup && x.SubGroup == newGroupTag).length > 0) {
                questionsWithGroup++;
                newGroupTag = `${groupTag}${questionsWithGroup}`;
            }

            //filter in this instance is byref
            var groupTemplates = this.cloneObject(
                this.pollQuestionGroupTemplates
            ).filter((x) => x.GroupTag == groupTag);
            let originalCodeMap = {};

            for (let i = 0; i < groupTemplates.length; i++) {
                let template = groupTemplates[i];
                let newCode = this.createUniqueCode();
                originalCodeMap[template.Code] = newCode;
                template.Code = newCode;
                template.SubGroup = newGroupTag;

                if (template.Conditions && template.Conditions.length > 0) {
                    for (let j = 0; j < template.Conditions.length; j++) {
                        let condition = template.Conditions[j];

                        condition.ReliesOnQuestionCode =
                            originalCodeMap[condition.ReliesOnQuestionCode];
                    }
                }

                groupOfQuestionsToAdd.push(template);
            }

            let groupInsertButtonPos = this.editPoll.Questions.findIndex(
                (x) =>
                    x.GroupTag == groupTag &&
                    x.QuestionType == "GroupInsertButton"
            );

            let insertButton = this.editPoll.Questions[groupInsertButtonPos];

            let groupDelete = new PollQuestion();
            groupDelete.QuestionType = "GroupDeleteButton";
            groupDelete.Response = insertButton.Response;
            groupDelete.GroupTag = insertButton.GroupTag;
            groupDelete.SubGroup = newGroupTag;
            groupOfQuestionsToAdd.push(groupDelete);

            //this is set to splice after the button because if you do it before it will change the rank count and thus you never get it to the limit no matter how many times you press.
            // let pos = (this.editPoll.Questions.length < groupInsertButtonPos + 1) ? this.editPoll.Questions.length : groupInsertButtonPos + 1;
            this.editPoll.Questions.splice(
                groupInsertButtonPos,
                0,
                ...groupOfQuestionsToAdd
            );
            this.bus.$emit("updateVisibility");
        },

        validateForm: function () {
            this.validateErrors = [];

            for (var i = 0; i < this.editPoll.Questions.length; i++) {
                var question = this.editPoll.Questions[i];
                Vue.set(question, "_HasError", null);

                if(!question.IsMandatory || question.Response) {

                    if(question.MappedTo 
                        && question.MappedTo.indexOf("Email") != -1) {

                        if(!question.Response || question.Response.indexOf("@") == -1) {
                            let errorMessage = `${question.Name} requires a valid email address. '${question.Response}' is not a valid email.`;
                            Vue.set(question, "_HasError", errorMessage);
                            this.validateErrors.push(errorMessage);
                            continue;
                        }

                        let isValidEmail = validator.isEmail(question.Response + '');

                        if(!isValidEmail) {
                            let errorMessage = `${question.Name} requires a valid email address. '${question.Response}' is not a valid email.`;
                            Vue.set(question, "_HasError", errorMessage);
                            this.validateErrors.push(errorMessage);
                            continue;
                        }
                    }

                    continue;
                }

                if(question.MappedTo && question.MappedTo == "Session") {
                    continue;
                }

                if(question.Conditions.length == 0) {
                    let errorMessage = question.Name + " is mandatory.";
                    Vue.set(question, "_HasError", errorMessage);
                    this.validateErrors.push(errorMessage);
                    continue;
                }


                var isVisible = false;

                for (var j = 0; j < question.Conditions.length; j++) {
                    var condition = question.Conditions[j];

                    var questionToCheck = this.editPoll.Questions.find(
                        (x) => x.Code == condition.ReliesOnQuestionCode
                    );

                    if(!questionToCheck) {
                        // For some reason, this question was not found.
                        // Skip it.
                        continue;
                    }

                    if(questionToCheck.Response
                        && questionToCheck.Response.localeCompare(condition.RequiredAnswer, undefined, {sensitivity: 'base'}) == 0) {
                        
                        isVisible = true;
                        continue;
                    }

                    isVisible = false;
                    break;
                }

                if (!isVisible) {
                    continue;
                }

                let errorMessage = question.Name + " is mandatory.";
                Vue.set(question, "_HasError", errorMessage);
                this.validateErrors.push(errorMessage);
            }
            return this.validateErrors.length == 0;
        },

        async onSubmitPollResponse() {
            this.submitting = true;

            if (!this.validateForm()) {
                this.submitting = false;
                return;
            }
            this.poll.ShowCode = this.showCode;
            
            if(this.poll.Id == 'details') {
                this.poll.Id = '0';
            }

            this.poll.Questions = this.editPoll.Questions.concat(
                this.pollQuestionGroupTemplates
            );

            try {
                let toPost = new FormData();

                this.poll.Questions.forEach((q) => {
                    if (
                        q.QuestionType != "ImageUpload" &&
                        q.QuestionType != "LargeUpload"
                    ) {
                        return;
                    }

                    if(q.FileInfo) {
                        let file = q.FileInfo;
                        toPost.append(`files`, file);
                    }
                });

                if(this.$route.query
                    && this.$route.query.ac
                    && this.$route.query.ac.length > 0) {
                    Vue.set(this.poll, 'RespondantAttendeeCode', this.$route.query.ac);
                }

                toPost.append("poll", JSON.stringify(this.poll));

                let postUrl = `${process.env.VUE_APP_API_URL}/api/poll/public`

                let r = await fetch(postUrl, {
                    mode: "cors",
                    method: "post",
                    headers: {
                        Accept: "application/json",
                    },
                    body: toPost,
                });

                r = await r.json();

                if (r.Errors.length > 0) {
                    this.submitting = false;
                    this.validateErrors = [r.Errors[0]];
                } else {

                    if(this.editPoll.RedirectToUrl) {
                        window.location.replace(this.editPoll.RedirectToUrl);
                    } else {
                        this.submitting = false;
                        this.success = "Thank you!";
                        this.fulfilledAttendees = r.Result.Attendees;
                        
                    }
                }
            } catch (e) {
                this.submitting = false;
                this.validateErrors = [
                    "We're sorry. Something happened when saving. Please try again.",
                ];
            }
        },

        onGetPoll: async function () {
            let responseCode = this.$route.query.r;
            let registrationCode = this.$route.query.rc;

            let inviteCode = this.$route.query.ic;


            var pollUrl = `${process.env.VUE_APP_API_URL}/api/poll/public/${this.showCode}/${this.pollCode}`;

            if(responseCode) {
                pollUrl = `${pollUrl}?r=${responseCode}`;

                if(registrationCode) {
                    pollUrl = `${pollUrl}&rc=${registrationCode}`;
                }
            }
            else if(registrationCode) {
                pollUrl = `${pollUrl}?rc=${registrationCode}`;
            } else if(inviteCode) {
                pollUrl = `${process.env.VUE_APP_API_URL}/api/poll/public/${this.showCode}/${this.pollCode}/${inviteCode}`
            }

            try {

                let r = await fetch(pollUrl, { mode: "cors" });

                r = await r.json();

                console.log(r.ErrorCodes)
                if (r.ErrorCodes.length > 0
                    && r.ErrorCodes[0] == 'ERR_POLL_CLOSED') {
                    this.pollClosedMessage = r.Result.Poll.ClosedMessage;
                    return;
                } else if (r.ErrorCodes.length > 0) {
                    this.error = r.Errors[0];
                    return;
                }

                this.show = r.Result.Show;
                this.poll = r.Result.Poll;

                try {
                    this.rebuildTheme();
                } catch(ex) {
                    console.error("Error on build theme.", ex);
                }

                this.editPoll = this.cloneObject(this.poll);

                this.editPoll.Questions = this.poll.Questions.filter(
                    (x) =>
                        !x.GroupTag ||
                        x.GroupTag == "" ||
                        x.QuestionType == "GroupInsertButton" ||
                        x.SubGroup
                );

                this.pollQuestionGroupTemplates = this.poll.Questions.filter(
                    (x) =>
                        x.GroupTag &&
                        x.GroupTag != "" &&
                        x.QuestionType != "GroupInsertButton" && 
                        !x.SubGroup
                );

                for (let i = 0; i < this.editPoll.Questions.length; i++) {
                    let pollQuestion = this.editPoll.Questions[i];
                    if (
                        !pollQuestion.Response &&
                        pollQuestion.QuestionType != "GroupInsertButton"
                    ) {
                        Vue.set(pollQuestion, "Response", null);
                    }
                }

                if (this.$route.query.qr) {
                    let qr = this.$route.query.qr.split(",");
                    for (let i = 0; i < qr.length; i++) {
                        let qir = qr[i].split(":");
                        if (this.editPoll.Questions.length < qir[0]) {
                            continue;
                        }
                        this.editPoll.Questions[qir[0]].Response = qir[1];
                    }
                }

                if(this.$route.query.qrr) {
                    let qr = this.$route.query.qrr.split(",");

                    for (let i = 0; i < qr.length; i++) {

                        let qir = qr[i].split(":");

                        let question = this.editPoll.Questions.find(x => x.Code == qir[0]);

                        if(!question) {
                            continue;
                        }

                        question.Response = qir[1];
                    }

                }

                if(registrationCode) {
                    let question = this.editPoll.Questions.find(x => x.MappedTo == 'CONTACT_RegCode');

                    if(question) {
                        question.Response = registrationCode;
                    }
                }

                for (let i = 0; i < this.editPoll.Questions.length; i++) {
                    let pollQuestion = this.editPoll.Questions[i];
                    if (
                        pollQuestion.WriteOnce == true &&
                        pollQuestion.Response != null
                    ) {
                        console.log("RCA:", this.responseCheckCodeArray);
                        
                        this.responseCheckCodeArray.push(pollQuestion.Code);
                    }
                }

                if(this.editPoll.LandingPageMessage && !responseCode && !registrationCode) {
                    this.showLandingPage = true;
                }


            } catch (ex) {
                this.ex = ex;
                console.error(ex);
                this.$router.push("/404");
            }
        },

        cloneObject(source) {
            if (source) {
                return JSON.parse(JSON.stringify(source));
            }

            return null;
        },

        createUniqueCode() {
            let guid = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(
                /[018]/g,
                (c) =>
                    (
                        c ^
                        (crypto.getRandomValues(new Uint8Array(1))[0] &
                            (15 >> (c / 4)))
                    )
                        .toString(16)
                        .toUpperCase()
            );
            
            guid = guid.replace(/-/g, "").toUpperCase();
            return guid;
        },
    },
};
</script>