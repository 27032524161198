import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Loading from './components/loading'
import titleComponent from './components/title.component'
import pollAnswerComponent from './components/poll.answer'
import MarkdownOutput from './components/markdown.view'

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap";
// import 'bootstrap-icons/font/bootstrap-icons.css'
import './styles/styles.sass'

Vue.component('poll-answer',pollAnswerComponent);
Vue.component('loading',Loading);
Vue.component('vue-title',titleComponent);
Vue.component('markdown-output', MarkdownOutput);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
